import "./Content.css";
import useWindowDimensions from "./hooks/useWindowDimensions";

export const Content = () => {
  const { width, height } = useWindowDimensions();
  const lowMobile = width <= 375 && height <= 667;
  const midMobile = width <= 425 && height <= 896;

  return (
    <div className="main">
      <div className="hero">
        <h1 className="title">Leon Küßner</h1>
        <h2 className="subtitle">Junior Frontend Developer</h2>
        <h2 className="cliffhanger">
          Coming soon {!(lowMobile || midMobile) ? '|' : ""}{" "}
          {(lowMobile || midMobile) ? <hr className="divider"/> : ""}
          <a
            target="_blank"
            type="application/pdf"
            href="/documents/cv.pdf"
          >
            View my CV (PDF)
          </a>
        </h2>
      </div>
      <img src="/images/jpg/visual.jpeg" className="splash-image" alt="splash" />
    </div>
  );
};

export default Content;
